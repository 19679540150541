import { sumBy, map } from "lodash";
import basketItem from "./components/basket-item/index.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import navigateTo from "../../../../mixins/buy-flow";

export default {
    name: "buy-flow",
    mixins: [navigateTo],
    data() {
        return {
            kod: "",
            showBonusesInput: false,
            bonusesAmount: 0,
            disabledButton: false,
        };
    },
    components: {
        basketItem,
    },
    mounted() {},
    created() {
        this.sync();
        console.log(this.basket, '23')
        const transformedProducts = this.basket.map((item) => {
            return {
                item_id: item.productPrices.data[0].article,
                item_name: item.title,
                affiliation: "Renew-Cosmetics",
                coupon: '',
                discount: 0.00,
                index: 0,
                item_brand: item.brand?.data?.title || 'Renew',
                item_category: item.brandSeries?.data?.title || '',
                item_category2: (() => {
                    if(!this.isAuthenticated) return 'unauthorized'
                    if(this.isProfessional) return 'professional'
                    if(this.isDistributor) return 'distributor'
                    return 'regular'
                })(),
                item_list_id: "Cosmetics",
                item_list_name: "Cosmetics",
                location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                price: item.productPrices.data[0].price,
                quantity: item.select_count,
            }
        })

        const sum = this.basket.reduce((acc, item) => {
            return acc + item.productPrices.data[0].price;
        }, 0);

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: "view_cart",
            ecommerce: {
                currency: "CZK",
                value: parseFloat(sum).toFixed(2),
                items: transformedProducts
            }
        });


    },
    watch: {
        bonusesAmount() {
            this.setBonuses({ pay_bonus_amount: this.bonusesAmount });
            this.$refs.bonusesError.innerHTML = "";
            this.disabledButton = false;

            if (this.bonusesAmount > this.user?.bonusAmount) {
                this.$refs.bonusesError.innerHTML = `${this.$t("available")}  ${this.user.bonusAmount} ${this.$t(
                    "bonuses"
                )}`;
                this.disabledButton = true;
            }
        },
    },
    computed: {
        ...mapGetters({
            basket: "basket/basket",
            isUserWithCode: "profile/isUserWithCode",
            user: "profile/user",
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
            isAuthenticated: "auth/isAuthenticated",
        }),
        checkBasketContainsPromotion() {
            return (
                this.basket.findIndex(
                    product => product.select_type.hasActivePromotion || product.select_type.hasIndividualDiscount
                ) !== -1
            );
        },
        totalSum() {
            let bonusesAmount = this.bonusesAmount <= this.user?.bonusAmount ? this.bonusesAmount : 0;

            return sumBy(this.basket, product => product.select_type.price * product.select_count) - bonusesAmount;
        },
        getTempDiscount() {
            return this.hasTempDiscount() ? (this.totalSum * 5) / 100 : 0;
        },
        discount() {
            if (!this.canSeeDiscount()) {
                return 0;
            }

            let sum = 0;

            this.basket.forEach(basketProduct => {
                const clientPrice = basketProduct.client_price;

                // do not calculate for regular users
                if (!clientPrice) {
                    return;
                }

                let discount = 0;

                const price = basketProduct.select_type;
                if (this.isProfessional) {
                    // for professionals calculate only individual discount
                    if (price.hasIndividualDiscount) {
                        discount = price.oldPrice - price.price;
                    }
                } else if (this.isDistributor) {
                    // discount = professional price - distributor price
                    discount = clientPrice.price - price.price;
                } else {
                    // for users with code calc actual discount, without promotion percent
                    if (clientPrice.hasActivePromotion) {
                        discount = clientPrice.oldPrice - price.oldPrice;
                    } else {
                        discount = clientPrice.price - price.price;
                    }
                }

                sum += discount * basketProduct.select_count;
            });

            return sum;
        },
    },
    methods: {
        ...mapActions({
            getSyncBasket: "basket/GET_SYNC_BASKET",
            fetchDefaultPrices: "basket/GET_DEFAULT_PRICE_BASKET",
            updateUserAssignCode: "profile/UPDATE_USER_DATA",
            fetchUser: "profile/FETCH_USER_DATA",
        }),
        ...mapMutations({
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            setBonuses: "basket/SET_USER_BONUSES",
        }),
        handleOrderClick() {
            // if (!this.isAuthenticated) {
            //     this.changeLoginPopup(true);
            //     return;
            // }
            this.navigateTo("buy-flow-order");
        },
        hasTempDiscount() {
            return this.user && this.user.tempDiscount;
        },
        canSeeDiscount() {
            return this.user && (this.isUserWithCode || this.isProfessional || this.isDistributor);
        },
        syncProfessional() {
            this.updateUserAssignCode({ professional_id: this.kod })
                .then(() => {
                    this.sync();
                    this.$toasted.success(this.$t("successChangeCosmetolog"));
                })
                .catch(() => {
                    this.$toasted.error(this.$t("errorCodeProfessional"));
                });
        },
        async sync() {
            const ids = map(this.basket, "id");
            await this.getSyncBasket({ ids });

            if (!this.isAuthenticated) {
                return;
            }

            await this.fetchUser();
            if (this.isUserWithCode || this.isProfessional || this.isDistributor) {
                await this.fetchDefaultPrices({ ids });
            }
        },
    },
};
